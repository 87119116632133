
<template>
  <div class="card flex justify-center">
    <div
      class="flex items-center cursor-pointer whitespace-nowrap border border-dark ps-2 pe-1 gap-2 rounded-md h-6"
      @click="categoriesPopover.toggle"
    >
      <span>
        TAGS
      </span>
      <div>
        <WellIcon
          v-show="categoriesPopover?.visible"
          icon="chevron-up"
          :size="18"
        />
        <WellIcon
          v-show="!categoriesPopover?.visible"
          icon="chevron-down"
          :size="18"
        />
      </div>
    </div>
    <Popover
      ref="categoriesPopover"
    >
      <div class="flex flex-col overflow-hidden rounded-md gap-2 p-4">
        <div
          v-for="option in categories"
          :key="option.id"
          @click="toggleCategory(option)"
        >
          <WellTag
            :outlined="!selectedCategoriesIds.includes(option.id)"
          >
            {{ option.name }}
          </WellTag>
        </div>
      </div>
    </Popover>
  </div>
</template>

<script setup lang="ts">
import WellTag from '@/components/common/WellTag.vue'
import WellIcon from '@/components/common/WellIcon.vue'

const categoriesPopover = ref()

const emit = defineEmits<{
  (event: 'toggleCategory', category: EventCategoriesResponse): void
}>()

const toggleCategory = (category: EventCategoriesResponse) => {
  emit('toggleCategory', category)
}

defineProps<{
  categories: EventCategoriesResponse[],
  selectedCategoriesIds: number[]
}>();
</script>
