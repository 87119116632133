<template>
  <span
    :class="[
      'ps-3 rounded-md text-sm uppercase h-6 inline-flex items-center border cursor-pointer gap-1',
      outlined ? 'border-dark text-dark' : 'bg-dark text-white',
      showCloseIcon ? 'pr-1' : 'px-3'
    ]"
  >
    <div>
      <slot></slot>
    </div>
    <WellIcon
      @click="emit('close')"
      v-if="showCloseIcon"
      icon="x"
    />
  </span>
</template>

<script setup lang="ts">
import WellIcon from '@/components/common/WellIcon.vue'

const emit = defineEmits(['close'])

defineProps({
  outlined: {
    type: Boolean,
    default: false
  },
  showCloseIcon: {
    type: Boolean,
    default: false
  }
});
</script>
