<template>
  <div>
    <div class="flex border-t border-b border-dark lg:px-12 flex-col lg:flex-row w-full">
      <!-- Search -->
      <div class="flex items-center gap-4 justify-between lg:ps-0 px-4">
        <form @submit.prevent="searchEvents" class="flex items-center">
          <label for="search" class="cursor-pointer pr-4">
            <WellIcon icon="search-md" :size="24" />
          </label>
          <input
            id="search"
            class="bg-transparent border-none placeholder-dark focus:outline-none py-4"
            v-model="search"
            type="text"
            placeholder="SEARCH"
          />
        </form>
        <CategoriesSelect
          v-if="!props.showDatePicker"
          class="lg:hidden"
          :categories="props.categories"
          :selectedCategoriesIds="props.selectedCategoriesIds"
          @toggleCategory="toggleCategory"
        />
      </div>
      <!-- Date Picker -->
      <div
        class="py-4 px-4 lg:px-7 border-t lg:border-t-0 lg:border-l border-dark flex items-center justify-between"
        v-if="props.showDatePicker"
      >
        <div class="w-52 flex items-center gap-4 flex-nowrap">
          <WellIcon icon="calendar" :size="24" />
          <div
            class="cursor-pointer"
            @click="datePickerPopover.toggle"
          >
            <span v-if="!date">
              DATE
            </span>
            <span v-else class="flex items-center gap-2">
              <span class="whitespace-nowrap">
                {{ $dayjs(date).format('MMM DD, YYYY') }}
              </span>
              <WellIcon
                class="cursor-pointer"
                icon="x"
                @click.stop="clearDate"
              />
            </span>
          </div>
          <Popover ref="datePickerPopover">
            <DatePicker
              v-model="date"
              inline
              @date-select="dateSelected"
            />
          </Popover>
        </div>
        <CategoriesSelect
          class="lg:hidden"
          :categories="props.categories"
          :selectedCategoriesIds="props.selectedCategoriesIds"
          @toggleCategory="toggleCategory"
        />
      </div>
      <div class="flex-1" v-else></div>
      <!-- Categories -->
      <div class="ps-7 pe-10 border-l border-dark overflow-x-hidden max-w-auto hidden lg:flex flex-col justify-center">
        <div class="relative">
          <Swiper
            :slides-per-view="'auto'"
            :spaceBetween="10"
            :modules="[SwiperNavigation]"
            :navigation="{
              nextEl: '.all-next',
              prevEl: '.all-prev',
            }"
          >
            <SwiperSlide
              class="w-auto slide"
              v-for="(category, $index) in props.categories"
              :key="$index"
            >
              <WellTag
                :selected="selectedCategoriesIds.includes(category.id)"
                @click="toggleCategory(category)"
                :outlined="!selectedCategoriesIds.includes(category.id)"
              >
                {{ category.name }}
              </WellTag>
            </SwiperSlide>
          </Swiper>
          <div class="custom-swiper-nav custom-swiper-prev all-prev bg-primary-contrast pr-2 h-6 flex items-center swiper-button-disabled">
            <WellIcon icon="arrow-left" :size="24" />
          </div>
          <div class="custom-swiper-nav custom-swiper-next all-next bg-primary-contrast h-6 flex items-center swiper-button-disabled">
            <WellIcon icon="arrow-right" :size="24" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineModel, defineEmits, withDefaults } from 'vue';
import WellTag from '@/components/common/WellTag.vue';
import WellIcon from '@/components/common/WellIcon.vue';
import DatePicker from 'primevue/datepicker';
import CategoriesSelect from '~/components/common/CategoriesSelect.vue';
const emit = defineEmits(['toggleCategory', 'searchEvents', 'clearDate'])

const datePickerPopover = ref();
const search = defineModel('search')
const date = defineModel('date')

function toggleCategory(category: EventCategoriesResponse) {
  emit('toggleCategory', category)
}

function searchEvents() {
  emit('searchEvents', search.value)
}

function clearDate() {
  emit('clearDate')
}

function dateSelected(event: any) {
  datePickerPopover.value.hide()
}

const props = withDefaults(defineProps<{
  categories: EventCategoriesResponse[]
  selectedCategoriesIds: EventCategoriesResponse[]
  showDatePicker: boolean
}>(), {
  showDatePicker: true,
});
</script>

<style lang="scss" scoped>
// swiper
.slide {
  width: auto;
}

.custom-swiper-nav {
  position: absolute;
  top: 0;
  z-index: 1;
}

.custom-swiper-prev {
  left: -20px;
}

.custom-swiper-next {
  right: -40px;
}

.custom-swiper-nav.swiper-button-disabled {
  display: none;
}
</style>